<template >
  <div class="container mt-5 container_estado" style="">
      
      <div class="ml-2 mb-4">
          <h2 class="text-center">{{tu_estado_cuentas}} {{ nameUser }}</h2>
      </div>
  
    <div class="my-4 ">
      <div style="align-items: baseline;" class="cliente_container">
        <div class="propiedades_container">
          <p><b>{{ nombre_propiedad }} </b> </p> 
          <select class="form-control selectProp" ref="select" @change="selectChange($event)" >
            <option value="seleccione" disabled selected="true">{{ seleccione_una_propiedad }}</option>  
            <option v-for="propiedad in propiedades" :value="propiedad" :key="propiedad">{{propiedad}}</option>
            </select>
        </div>
        <br>
        <p><b>{{saldo_pendiente}}: </b> <i class="saldo_pendiente">&#8364;{{ saldoCuenta }}</i></p>
        <div class="fechas_container">
          <label for="fecha1"><b>{{desde}}</b></label> 
          <input type="date" name="fecha1" @change="handleDateFilter" v-model="fecha1">
          <label for="fecha2"><b>{{hasta}}</b></label>
          <input type="date" name="fecha2" @change="handleDateFilter" v-model="fecha2">

        </div>
                  
      </div>
      <!-- <b-avatar class="bg-avatar zoom" variant="transparent" size="4.5rem"><img src="../../assets/plus_img.png" alt=""></b-avatar> -->
        <!-- Selecciona un archivo -->
        
      
     
    </div>


    <div v-if="loading" class="load-4">
      <div class="ring-1"></div>
    </div>
    <!-- data table -->
    <div v-else class="container-fluid tabla_no_padding mt-5"  >
      <div>
        <b-table class="animate__animated animate__fadeInUp" sticky-header striped outlined bordered head-variant="dark" hover :items="items_table" :fields="fields" >
          <template #cell(LIQUIDACION)="data" >
              <button :disabled="modalLoading" @click="handleVerLiquidacion(data.item)" class="btn btn-dark">{{ ver_liquidacion }}</button>
          </template>
          
        </b-table>
      </div>
       <div v-if="modalLoading" class="load-4 modalLoding">
        <div class="ring-1"></div>
      </div>
      <dialog ref="modal" class="modal_liquidacion">
      
         <b-table class="animate__animated animate__fadeInUp" striped outlined bordered head-variant="dark" hover :items="liquidacion" :fields="fielsdsLiquidacion" ></b-table>
        <form method="dialog">
          <button value="cancel">Ok</button>
        </form>
        
      </dialog>
    </div>

  </div>
</template>

<script>
import { counter } from '@fortawesome/fontawesome-svg-core';

// import FormVue from "@/components/Form.vue";
export default {
  components: {
    // FormVue,
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      alerta_message:"",
      tu_estado_cuentas:"",
      nombre_propiedad:"",
      seleccione_una_propiedad:"",
      saldo_pendiente:"",
      desde:"",
      hasta:"",
      FECHA_text:"",
      FACTURA_text: "",
      CONCEPTO_text: "",
      VALOR_text: "",
      ESTATUS_text: "",
      LIQUIDACION_text: "",
      ver_liquidacion:"",
      pendiente:"PENDIENTE",
      pagada:"PAGADA",
      fecha1:null,
      fecha2:null,
      modalLoading:false,
      currentIdsReservas :[],
      loading:true,
      saldoCuenta:0,
      img: 0,
      perPage: 25,
      currentPage: 1,
      filter: null,
      table: "users",
      singleTable: "user",
      tableSpanish: "Mi Perfil",
      nameUser: JSON.parse(localStorage.getItem('user')).propietaro.NOMBRE.trim(),
      items: [],
      propiedades: JSON.parse(localStorage.getItem('user')).propiedades.map(propiedad=>propiedad.NOMBRE_PROPIEDAD.trim()) ,
      currentPropiedad:'',
      currentLiquidacion:{},
      row:{},
      user:{},
      file1: null,
      filename1: null,
      fields: [
        { key: 'FECHA', label:"" },
        { key: 'FACTURA' , label: "" },
        { key: 'CONCEPTO' , label: "" },
        { key: 'VALOR', class:'comision' , label: "" },
        { key: 'ESTATUS', label: "" },
        { key: 'LIQUIDACION', label: "" }
      ] , 
      items_table: [
        ],
      fielsdsLiquidacion:[
        { key: 'ID_RESERVA' , label:"" },
        { key: 'CHECKIN' },
        { key: 'CHECKOUT' },
        { key: 'HUESPED', label: "" },
        { key: 'PRECIO', class:'profit' , label: "" },
        { key: 'LIMPIEZA', class: 'profit', label:"" },
        { key: 'TOTAL', class: 'profit'  },
        { key: 'COMISION_DEL_PORTAL', class:'comision', label: "" },
        { key: 'GESTION_AGENCIA', class: 'comision', label: "" }
      ],
      liquidacion:[
        ] 
    };
  },
  async mounted() {
    //this.user = JSON.parse(localStorage.getItem('user')) || {}
    //this.getData();
    
    this.currentPropiedad = this.$refs.select.value
    this.loading = true
    this.llenarCampos()
    this.gettingFacturas().then(el=>this.loading = false)
     this.$store.watch(
      (state) => [state.recarga].join(),
      async () => {
        // this.showItemsNumber();
        // this.refreshButton();

        await this.llenarCampos()
        //console.log(this.textos)
      }
    );
  
  },
  methods: {

    llenarCampos(){
     let textos = JSON.parse(localStorage.getItem("text"))  
     this.alerta_message = textos.alerta_error_facturas
     this.tu_estado_cuentas=  textos.cliente_mis_cuentas_title 
     
     this.nombre_propiedad= textos.cliente_mis_cuentas_label_nombrepropiedad
     this.seleccione_una_propiedad= textos.cliente_mis_cuentas_input_select
     this.saldo_pendiente= textos.cliente_mis_cuentas_label_saldo
     this.desde= textos.cliente_reservas_label_desde
     this.hasta= textos.cliente_reservas_label_hasta
     this.FECHA_text= textos.cliente_table_mis_cuentas_fecha
     this.fields[0].label = this.FECHA_text
     this.FACTURA_text= textos.cliente_table_mis_cuentas_factura
     this.fields[1].label = this.FACTURA_text
     this.CONCEPTO_text= textos.cliente_table_mis_cuentas_concepto
     this.fields[2].label = this.CONCEPTO_text
     this.VALOR_text= textos.cliente_table_mis_cuentas_valor
     this.fields[3].label = this.VALOR_text
     this.ESTATUS_text= textos.cliente_table_mis_cuentas_estatus
     this.fields[4].label = this.ESTATUS_text
     this.LIQUIDACION_text= textos.cliente_table_mis_cuentas_liquidacion
     this.fields[5].label = this.LIQUIDACION_text
     this.ver_liquidacion= textos.cliente_table_mis_cuentas_button_liquidacion
     this.pagada= textos.cliente_table_mis_cuentas_pagada
     this.pendiente = textos.cliente_table_estado_pendiente

     this.fielsdsLiquidacion[0].label = textos.cliente_table_reservas_idreserva
     this.fielsdsLiquidacion[3].label = textos.cliente_table_reservas_huesped
     this.fielsdsLiquidacion[4].label = textos.cliente_table_reservas_precio_reserva.split('_')[0]
     this.fielsdsLiquidacion[5].label = textos.cliente_table_reservas_limpieza
     this.fielsdsLiquidacion[7].label = textos.cliente_table_reservas_comision_portal.split('_').join(" ")
     this.fielsdsLiquidacion[8].label = textos.gestion_agencia 
    },
    // async getData() {
    //   try {
    //     let res = await this.$store.dispatch("get", { path: 'clientes/get/' + this.user.id});
    //     this.row = res
    //     console.log(res)
    //     this.row.confirmaClave = this.row.clave 
    //   } catch (error) {
    //     alert(error);
    //   }
    // },
    handleDateFilter() {
      let fechaDesde = new Date(this.fecha1)
      let fechaHasta = new Date(this.fecha2)
      let facturasFiltradas = []  
      let facturas = JSON.parse(localStorage.getItem('facturas'))
       let contingIds = []
      facturas.map(factura => {
        let facturaSeconds = new Date(factura.fecha).getTime()
        if (facturaSeconds >= fechaDesde && facturaSeconds <= fechaHasta) {

          let fechaFormat = `${new Date(factura.fecha).getDate()}/${new Date(factura.fecha).getMonth() + 1}/${new Date(factura.fecha).getFullYear()}`

          let formatFactura = {
            FECHA: fechaFormat,
            FACTURA: factura.NUMERO,
            CONCEPTO: factura.concepto1,
            VALOR: '\u20AC ' + factura.ptadebe,
            ESTATUS: factura.fe_cobro === null ? this.pendiente : this.pagada
          }
          
          
          if (formatFactura.VALOR !== 0 && !contingIds.includes(formatFactura.FACTURA.trim())) {
            facturasFiltradas.push(formatFactura)
            contingIds.push(formatFactura.FACTURA.trim())
          }

        }


      })
      this.items_table = facturasFiltradas
      console.log(this.items_table)
    },
    selectChange(e){
      
      this.currentPropiedad = this.$refs.select.value
      let facturas = JSON.parse(localStorage.getItem('facturas')) 
      this.items_table = []
      this.saldoCuenta = 0
      let contingIds = []
      
      facturas.map(factura => {
        
        let fechaEntrada = `${new Date(factura.fecha).getDate()}/${new Date(factura.fecha).getMonth() + 1}/${new Date(factura.fecha).getFullYear()}`
        
        
        if (factura.NOMBRE_PROPIEDAD.trim() === this.currentPropiedad.trim()) {
          let facturasEsquema = {
            FECHA: fechaEntrada,
            FACTURA: factura.NUMERO,
            CONCEPTO: factura.concepto1,
            VALOR: '\u20AC '+ factura.ptadebe,
            ESTATUS: factura.fe_cobro === null ? this.pendiente : this.pagada,
            //LOS IDS de las reservas para obtener los datos
            idsReservas:factura.CONCEPTO,


          }
          if (facturasEsquema.ESTATUS === this.pendiente && !contingIds.includes(facturasEsquema.FACTURA.trim())) {
            this.saldoCuenta += factura.ptadebe
          }
          if (facturasEsquema.VALOR !== 0 && !contingIds.includes(facturasEsquema.FACTURA.trim())) {

            this.items_table.push(facturasEsquema)
            contingIds.push(facturasEsquema.FACTURA.trim())
          }
        }


      })
      
   //    this.gettingFacturas().then(el=>this.loading = false) 
      
    },
    async handleVerLiquidacion(item){
      this.modalLoading = true
      this.liquidacion = []
      let idsUnic = {}
      //
      
      let facturasReservas = JSON.parse(localStorage.getItem('facturas')).filter(el=>{ 
        
        return el.NUMERO.trim() === item.FACTURA.trim() })
      
      facturasReservas.filter((indice)=>{
          
          if(indice.CONCEPTO !== null){
            if(indice.CONCEPTO.trim().match(/R\d+/g)){
              console.log(indice)
               return Object.prototype.hasOwnProperty.call(idsUnic, indice.CONCEPTO.trim().match(/R\d+/g)[0]) ? false : (idsUnic[indice.CONCEPTO.trim().match(/R\d+/g)[0]] = indice.CONCEPTO.trim().match(/R\d+/g)[0].split('R')[1])
            }
           
          }else{
           return
          }

        
        
      })
      let valores = Object.values(idsUnic)
      if(valores.length !== 0){
        try {
          for (let i = 0; i < valores.length; i++) {
            let data = await fetch(`${this.$store.state.urlClients}facturas/reservas/${valores[i]}`)
            let reservs = await data.json()
            let reservas = reservs.reservas[0]
            let fechaEntrada = `${new Date(reservas.fecha_entrada).getDate()}/${new Date(reservas.fecha_entrada).getMonth() + 1}/${new Date(reservas.fecha_entrada).getFullYear()}`

            let fechaSalida = `${new Date(reservas.fecha_salida).getDate()}/${new Date(reservas.fecha_salida).getMonth() + 1}/${new Date(reservas.fecha_salida).getFullYear()}`


            let formatModalReserva = {
              ID_RESERVA: reservas?.ID_RESERVA === undefined ? null : reservas.ID_RESERVA,
              CHECKIN: reservas?.fecha_entrada === undefined ? null : fechaEntrada,
              CHECKOUT: reservas?.fecha_salida === undefined ? null : fechaSalida,
              HUESPED: `${reservas.NOMBRE} ${reservas.APELLIDO}`,
              PRECIO: '\u20AC ' + (reservas?.PRECIO === undefined ? 0 : reservas?.PRECIO),
              LIMPIEZA: '\u20AC ' + (reservas?.GASTOLIMPIEZAACOBRAR_PROPIETARIO === null ? 0 : reservas?.GASTOLIMPIEZAACOBRAR_PROPIETARIO),
              TOTAL: '\u20AC ' + ((reservas?.GASTOLIMPIEZAACOBRAR_PROPIETARIO === null ? 0 : reservas?.GASTOLIMPIEZAACOBRAR_PROPIETARIO) + (reservas?.PRECIO === null ? 0 : reservas?.PRECIO)),
              COMISION_DEL_PORTAL: reservas?.COMISION === undefined ? ('\u20AC ' + 0) : '\u20AC ' + reservas.COMISION
            }

            if (reservas.COMISION_IMPORTEYLIMPIEZA) {

              let porcentajeDeLinetor = ((reservas?.GASTOLIMPIEZAACOBRAR_PROPIETARIO === null ? 0 : reservas?.GASTOLIMPIEZAACOBRAR_PROPIETARIO) + (reservas?.PRECIO === null ? 0 : reservas?.PRECIO)) * (reservas?.PORCENTAJE_LINETOR / 100)

              formatModalReserva.GESTION_AGENCIA = '\u20AC ' + porcentajeDeLinetor
              this.liquidacion.push(formatModalReserva)
            } else {
              let porcentajeDeLinetor = ((reservas?.PRECIO === null ? 0 : reservas?.PRECIO)) * (reservas?.PORCENTAJE_LINETOR / 100)

              formatModalReserva.GESTION_AGENCIA = '\u20AC ' + porcentajeDeLinetor
              this.liquidacion.push(formatModalReserva)
            }

          }
        } catch (error) {
          alert('Oops, ha ocurrido un error')
          window.location.reload()
        }
        this.modalLoading = false
        this.$refs.modal.showModal()
      }else{
        this.modalLoading = false
        alert(this.alerta_message)       
      }
      
      
     // localStorage.setItem('currentLiquidacion', JSON.stringify(item))
  
      
      
    },

    async gettingFacturas(){
      let userID = JSON.parse(localStorage.getItem('user')).propietaro.ID_PROPIETARIO

      let facturas = await this.$store.dispatch('fecthFacturas', {path: `${this.$store.state.urlClients}facturas/${userID}`})
      localStorage.setItem('facturas', JSON.stringify(facturas))
      this.items_table = []
      
      this.saldoCuenta = 0  
      
      facturas.map(factura=>{
        let fechaEntrada = `${new Date(factura.fecha).getDate()}/${new Date(factura.fecha).getMonth() + 1}/${new Date(factura.fecha).getFullYear()}`
        
        if(factura.NOMBRE_PROPIEDAD.trim() === this.currentPropiedad.trim()){
          let facturasEsquema = {
            FECHA: fechaEntrada,
            FACTURA: factura.NUMERO,
            CONCEPTO: factura.concepto1,
            VALOR: factura.ptadebe,
            ESTATUS: factura.fe_cobro === null ? this.pendiente : this.pagada
          }
          if(facturasEsquema.ESTATUS === this.pendiente){
            this.saldoCuenta+= factura.ptadebe
          }
          if(facturasEsquema.VALOR!==0 ){
            
            this.items_table.push(facturasEsquema)
            
          }
        }
        
      
      })
      
    }
  
  },
  watch:{
    currentPropiedad(old,newP){
      console.log(newP)
      
    }
  }
};
</script>

<style>
.container_estado{
  font-size: 14px;
}
p{
  padding: 0;
  margin: 0;
}
td{
  font-size: 13px;
 
}
.tabla_no_padding{
  padding: 0;
  
}
.fechas_container{
    display: flex;
    align-items: baseline;
    gap: 6px;
 }
.saldo_pendiente{
  color: red;
  font-weight: bold;
}
.cliente_container{
  display: flex;
  justify-content: space-evenly;
  align-items: center;

}
.propiedades_container{
  display: flex;
  align-items: center;
}
.load-4 .ring-1 {
  animation: loadingD 1.5s 0.3s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}
.selectProp{
  width:  50%;
  font-size: 12px;
  margin-left: 6px;
}
.ring-1 {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  padding: 10px;
  border: 7px dashed #4b9cdb;
  border-radius: 100%;
}
.modalLoding{
  position: absolute;
  top: calc(50% - 108px  );
  left: calc(50%);
}
.modal_liquidacion {
  border: none !important;
  border-radius: 8px;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  padding: 1.6rem;
}
.modal_liquidacion button{
  padding: 8px 16px;
  background-color: #2889d3;
  color: white;
  font-weight: bold;
  border: none;
}
.profit{
  color: green;
}
.comision{
  color: red;
}

@keyframes loadingD {
  0%{
    transform: rotate(0deg);
  }
   50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }

  
}
@media (max-width: 750px) {
 .fechas_container{
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
 }
 .fechas_container label{
    margin: 0;
 }
 .cliente_container{
    gap: 12px !important; 
    flex-direction: column;
    align-items: baseline;
 }
 }</style>